import { h } from 'preact'
import './Orders.scss'
import httpClient from '../services/httpClient'
import { useState, useEffect } from 'preact/hooks'
import ROUTES from '../routes'
import { Order } from '../types/order'
import { User } from '../types/user'
import { CoachingClass } from '../types/coaching_class'
import { CoachingClassSession } from '../types/coaching_class_session'
import moment from 'moment'
import { RoutableProps } from 'preact-router'
import InvoiceGymspot from './InvoiceGymspot'
import Coach from '../types/coach'

interface Props extends RoutableProps {
  matches?: { session_id?: string }
}

export default (props: Props) => {
  const [orders, setOrders] = useState<Order[]>([])
  const [filteredOrders, setFilteredOrders] = useState<Order[]>([])
  const [users, setUsers] = useState<User[]>([])
  const [coaches, setCoaches] = useState<Coach[]>([])
  const [coachingClasses, setCoachingClasses] = useState<CoachingClass[]>([])
  const [sessions, setSessions] = useState<CoachingClassSession[]>([])
  const [orderForInvoice, setOrderForInvoice] = useState<Order>(undefined)
  const [query, setQuery] = useState('')

  useEffect(() => {
    fetchOrders()
    fetchCoaches()
    fetchUsers()
    fetchCoachingClasses()
    fetchSessions()
  }, [])

  useEffect(() => {
    let res = [...orders]

    if (props.matches?.session_id) {
      res = orders.filter((o) =>
        o.sessionIds.includes(props.matches.session_id)
      )
    }
    res = res.filter(
      (o) =>
        coachingClasses
          .find(
            (cc) =>
              cc.id ==
              sessions.find((s) => s.id == o.sessionIds[0])?.coachingClassId
          )
          ?.name.toLowerCase()
          .includes(query.toLowerCase()) ||
        `${users.find((u) => u.id == o.userId)?.firstName} ${
          users.find((u) => u.id == o.userId)?.lastName
        }`
          .toLowerCase()
          .includes(query.toLowerCase())
    )
    setFilteredOrders(res)
  }, [orders, query, users, sessions])

  const fetchOrders = async () => {
    try {
      console.log('FETCH')

      const res = await httpClient.req(ROUTES.FETCH_ORDERS({}))

      console.log('DONE: ', res)
      setOrders(res)
    } catch (e) {
      console.warn(e)
    }
  }

  const fetchCoaches = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_COACHES({}))

      setCoaches(res)
    } catch (e) {
      console.warn(e)
    }
  }

  const fetchUsers = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_USERS({}))

      setUsers(res)
    } catch (e) {
      console.warn(e)
    }
  }

  const fetchCoachingClasses = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_COACHING_CLASSES({}))

      setCoachingClasses(res)
    } catch (e) {
      console.warn(e)
    }
  }

  const fetchSessions = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_ALL_SESSIONS({}))

      setSessions(res)
    } catch (e) {
      console.warn(e)
    }
  }

  const generateInvoice = (order: Order) => {
    setOrderForInvoice(order)
  }

  const coachingClassForInvoice = () => {
    const session = sessions.find((s) => orderForInvoice.sessionIds[0] == s.id)

    return coachingClasses.find((cc) => cc.id == session.coachingClassId)
  }

  return (
    <div class="Orders">
      {orderForInvoice && (
        <InvoiceGymspot
          order={orderForInvoice}
          recipient={{
            name: `${
              users.find((u) => u.id == orderForInvoice.userId).firstName
            } ${users.find((u) => u.id == orderForInvoice.userId).lastName}`,
            address: users.find((u) => u.id == orderForInvoice.userId).address,
            zipcode: users.find((u) => u.id == orderForInvoice.userId).zipcode,
            city: users.find((u) => u.id == orderForInvoice.userId).city,
          }}
          coachingClasses={coachingClasses}
          sessions={sessions}
          coaches={[
            coaches.find((c) => c.id == coachingClassForInvoice().coachId),
          ]}
          coachInvoice
        />
      )}
      <div class="Orders__header">
        <div class="Orders__title">
          Commandes <span>(total: {orders.length})</span>
        </div>
        <div class="Orders__search">
          <i>search</i>
          <input
            value={query}
            onInput={(e: any) => setQuery(e.target.value)}
            placeholder="Rechercher une commande par nom de cours ou nom d'utilisateur"
          ></input>
        </div>
      </div>

      <div class="Orders__content">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Cours</th>
              <th>Utilisateur</th>
              <th>Prix total</th>
              <th>Date de commande</th>
              <th>Contient une session annulée</th>
              <th>Facture</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredOrders
              .sort((o1, o2) => (o1.createdAt > o2.createdAt ? -1 : 1))
              .map((o) => (
                <tr
                  class={
                    sessions.filter(
                      (s) => o.sessionIds.includes(s.id) && s.canceled
                    ).length > 0
                      ? 'Orders__row Orders__row--warn'
                      : 'Orders__row'
                  }
                >
                  <td>
                    <a href={`/orders/${o.id}`}>{o.id}</a>
                  </td>
                  <td>
                    {o.sessionIds.length} session(s) pour{' '}
                    {
                      coachingClasses.find(
                        (cc) =>
                          cc.id ==
                          sessions.find((s) => s.id == o.sessionIds[0])
                            ?.coachingClassId
                      )?.name
                    }
                  </td>
                  <td>
                    {users.find((u) => u.id == o.userId)?.firstName}{' '}
                    {users.find((u) => u.id == o.userId)?.lastName}
                  </td>
                  <td>{o.totalPrice}€</td>
                  <td>{moment(o.createdAt).format('LLL')}</td>
                  <td>
                    {sessions.filter(
                      (s) => o.sessionIds.includes(s.id) && s.canceled
                    ).length > 0
                      ? 'Oui'
                      : 'Non'}
                  </td>
                  <td>
                    {o.invoiceUrl ? (
                      <a href={o.invoiceUrl} target="_blank">
                        Facture
                      </a>
                    ) : (
                      <a onClick={() => generateInvoice(o)}>
                        Générere la facture manquante
                      </a>
                    )}
                  </td>
                  <td class="Orders__actions">
                    <a
                      href={`https://dashboard.stripe.com/payments/${o.stripePaymentIntentId}`}
                    >
                      Rembourser
                    </a>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
