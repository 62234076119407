import { h } from 'preact'
import './CoachForm.scss'
import { useState, useEffect } from 'preact/hooks'
import { Coach } from '../../types/coach'
import httpClient from '../../services/httpClient'
import ROUTES from '../../routes'
import Materialize from 'materialize-css'

interface Props {
  coach?: Coach
  onSave: (b: Coach) => void
}

export default (props: Props) => {
  const [coach, setCoach] = useState<Coach>(
    props.coach || {
      firstName: '',
      lastName: '',
      description: '',
      qualifications: '',
      pictureUrl: '',
      location: '',
    }
  )

  useEffect(() => {
    Materialize.updateTextFields()
  }, [])

  const onSave = () => {
    props.onSave(coach)
  }

  const uploadPhoto = async (e: any) => {
    const res = await httpClient.req(
      ROUTES.UPLOAD_FILE({ file: e.target.files[0] })
    )

    setCoach({ ...coach, pictureUrl: res.fileUrl })
  }

  return (
    <div class="CoachForm">
      <div class="CoachForm__content">
        <div class="CoachForm__left">
          <div class="input-field">
            <label for="coach_first_name">Prénom</label>
            <input
              id="coach_first_name"
              type="text"
              value={coach.firstName}
              onChange={(e: any) =>
                setCoach({ ...coach, firstName: e.target.value })
              }
              class="CoachForm__name"
            />
          </div>
          <div class="input-field">
            <label for="coach_last_name">Nom</label>
            <input
              id="coach_last_name"
              type="text"
              value={coach.lastName}
              onChange={(e: any) =>
                setCoach({ ...coach, lastName: e.target.value })
              }
              class="CoachForm__name"
            />
          </div>
          <div class="input-field">
            <label for="coach_location">Localisation</label>
            <input
              id="coach_location"
              type="text"
              value={coach.location}
              onChange={(e: any) =>
                setCoach({ ...coach, location: e.target.value })
              }
              class="CoachForm__location"
            />
          </div>
          <div class="input-field">
            <label for="coach_description">Description</label>
            <textarea
              id="coach_description"
              value={coach.description}
              onChange={(e: any) =>
                setCoach({ ...coach, description: e.target.value })
              }
              class="CoachForm__description materialize-textarea"
            />
          </div>
          <div class="input-field">
            <label for="coach_qualifications">Qualifications</label>
            <textarea
              id="coach_qualifications"
              value={coach.qualifications}
              onChange={(e: any) =>
                setCoach({ ...coach, qualifications: e.target.value })
              }
              class="CoachForm__qualifications materialize-textarea"
            />
          </div>
        </div>
        <div class="CoachForm__right">
          {coach.pictureUrl ? (
            <img src={coach.pictureUrl} />
          ) : (
            <div class="CoachForm__imagePlaceholder">No image yet</div>
          )}
          <label class="CoachForm__fileInput">
            <input type="file" onChange={uploadPhoto} />
            Edit
          </label>
        </div>
      </div>
      <div className="CoachForm__actions">
        <div class="CoachForm__button" onClick={onSave}>
          VALIDATE
        </div>
      </div>
    </div>
  )
}
