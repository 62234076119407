import { h } from 'preact'
import './CategoryForm.scss'
import { useState, useEffect } from 'preact/hooks'
import { Category } from '../../types/category'
import httpClient from '../../services/httpClient'
import ROUTES from '../../routes'
import Materialize from 'materialize-css'

interface Props {
  category?: Category
  categories: Category[]
  onSave: (b: Category) => void
}

export default (props: Props) => {
  const [category, setCategory] = useState<Category>(
    props.category || {
      name: '',
      iconUrl: '',
    }
  )

  useEffect(() => {
    let elems = document.querySelectorAll('select')

    M.FormSelect.init(elems, {})
    Materialize.updateTextFields()
  }, [])

  const onSave = () => {
    props.onSave(category)
  }

  const uploadPhoto = async (e: any) => {
    const res = await httpClient.req(
      ROUTES.UPLOAD_FILE({ file: e.target.files[0] })
    )

    setCategory({ ...category, iconUrl: res.fileUrl })
  }

  return (
    <div class="CategoryForm">
      <div class="CategoryForm__content">
        <div class="CategoryForm__left">
          <div class="input-field">
            <label for="category_name">Nom</label>
            <input
              id="category_name"
              type="text"
              value={category.name}
              onChange={(e: any) =>
                setCategory({ ...category, name: e.target.value })
              }
              class="CategoryForm__name"
            />
          </div>
          <div class="input-field">
            <select
              onChange={(e: any) =>
                setCategory({ ...category, categoryId: e.target.value })
              }
            >
              <option value={null} selected={!category.categoryId}>
                No parent category
              </option>
              {props.categories.map((c) => (
                <option value={c.id} selected={category.categoryId == c.id}>
                  {c.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div class="CategoryForm__right">
          {category.iconUrl ? (
            <img src={category.iconUrl} />
          ) : (
            <div class="CategoryForm__imagePlaceholder">Aucune image</div>
          )}
          <label class="CategoryForm__fileInput">
            <input type="file" onChange={uploadPhoto} />
            Éditer
          </label>
        </div>
      </div>
      <div className="CategoryForm__actions">
        <div class="CategoryForm__button" onClick={onSave}>
          VALIDER
        </div>
      </div>
    </div>
  )
}
