import { h } from 'preact'
import './Coaches.scss'
import { useEffect, useState } from 'preact/hooks'
import httpClient from '../services/httpClient'
import ROUTES from '../routes'
import Toastify from 'toastify-js'
import Modal from '../components/Modal'
import { RoutableProps } from 'preact-router'
import { Coach } from '../types/coach'
import CoachForm from './CoachForm'
import { User } from '../types/user'
import CoachDocument from '../types/coach_document'
import moment from 'moment'

export default (_: RoutableProps) => {
  const [coaches, setCoaches] = useState<Coach[]>([])
  const [filteredCoaches, setFilteredCoaches] = useState<Coach[]>([])
  const [users, setUsers] = useState<User[]>([])
  const [coachDocuments, setCoachDocuments] = useState<CoachDocument[]>([])
  const [selectedCoach, setSelectedCoach] = useState<Coach>(undefined)
  const [showFormModal, setShowFormModal] = useState(false)
  const [query, setQuery] = useState('')
  const [personalChecked, setPersonalChecked] = useState(false)

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    refreshFilter()
  }, [coaches, query, personalChecked])

  const fetchData = async () => {
    await fetchDocuments()
    await fetchUsers()
    await fetchCoaches()
  }

  const fetchCoaches = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_COACHES({}))

      res.sort((c1: Coach, c2: Coach) => {
        if (c1.createdAt == c2.createdAt) {
          return c1.lastName > c2.lastName ? 1 : -1
        } else {
          return c1.createdAt > c2.createdAt ? -1 : 1
        }
      })
      setCoaches(res)
    } catch (e) {
      console.warn(e)
    }
  }

  const fetchDocuments = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_ALL_COACH_DOCUMENTS({}))

      setCoachDocuments(res)
    } catch (e) {
      console.warn(e)
    }
  }

  const fetchUsers = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_USERS({}))

      setUsers(res)
    } catch (e) {
      console.warn(e)
    }
  }

  const refreshFilter = () => {
    let res = [...coaches]

    console.log(res)
    if (personalChecked) {
      res = res.filter(
        (c) =>
          !c.coords ||
          !c.personalCoachingPrice ||
          c.personalCoachingCategories.length == 0
      )
    }
    res = res.filter(
      (c: Coach) =>
        `${(c.firstName || '').toLocaleLowerCase()} ${(
          c.lastName || ''
        ).toLocaleLowerCase()}`.includes(query.toLocaleLowerCase()) ||
        users
          .find((u) => u.id == c.userId)
          ?.email.toLocaleLowerCase()
          .includes(query.toLocaleLowerCase())
    )
    setFilteredCoaches(res)
  }

  const openFormModal = () => {
    setShowFormModal(true)
  }

  const closeFormModal = () => {
    setSelectedCoach(undefined)
    setShowFormModal(false)
  }

  const editCoach = (coach: Coach) => () => {
    setSelectedCoach(coach)
    setShowFormModal(true)
  }

  const deleteCoach = (coach: Coach) => async () => {
    try {
      await httpClient.req(ROUTES.DELETE_COACH(coach))
      setCoaches([...coaches.filter((cc) => cc.id != coach.id)])
    } catch (err) {
      Toastify({
        text: 'Could not delete coach',
        duration: 5000,
        stopOnFocus: true,
        backgroundColor: '#f7436d',
      }).showToast()

      console.warn(err)
    } finally {
      setSelectedCoach(undefined)
    }
  }

  const onCoachSave = async (coach: Coach) => {
    try {
      const route = coach.id ? ROUTES.UPDATE_COACH : ROUTES.CREATE_COACH
      const res = await httpClient.req(route(coach))

      if (coach.id) {
        let idx = coaches.findIndex((cc) => cc.id === coach.id)

        coaches[idx] = res
        setCoaches([...coaches])
      } else {
        setCoaches([...coaches, res])
      }

      setShowFormModal(false)
    } catch (err) {
      Toastify({
        text: 'Could not save coach',
        duration: 5000,
        stopOnFocus: true,
        backgroundColor: '#f7436d',
      }).showToast()
      console.warn(err)
    } finally {
      setSelectedCoach(undefined)
    }
  }

  return (
    <div class="Coaches">
      <div class="Coaches__header">
        <div class="Coaches__title">
          Coaches <span>(total: {coaches.length})</span>
        </div>
        <div class="Coaches__addButton" onClick={openFormModal}>
          <i>add</i>
        </div>
        {showFormModal && (
          <Modal onClose={closeFormModal}>
            <CoachForm coach={selectedCoach} onSave={onCoachSave} />
          </Modal>
        )}
        <div class="Coaches__filters">
          <div class="Coaches__search">
            <i>search</i>
            <input
              value={query}
              onInput={(e: any) => setQuery(e.target.value)}
              placeholder="Search a user by name or email..."
            ></input>
          </div>
          <label class="Coaches__personalCheckbox">
            <input
              type="checkbox"
              class="filled-in"
              checked={personalChecked}
              onChange={(e: any) => setPersonalChecked(e.target.checked)}
            />
            <span>
              Afficher uniquement les coachs ne proposant pas de coaching
              personnel
            </span>
          </label>
        </div>
      </div>
      <div class="Coaches__content">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nom</th>
              <th>Email</th>
              <th>Téléphone</th>
              <th>Localisation</th>
              <th>Photo</th>
              <th>Documents</th>
              <th>Coaching personnel ?</th>
              <th>Validé</th>
              <th>Création</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredCoaches.map((c) => {
              let validation = coachDocuments.filter(
                (cd) => cd.coachId == c.id
              ).length

              if (c.stripeUserId) {
                validation += 1
              }
              return (
                <tr>
                  <td>
                    <a href={`/coaches/${c.id}`}>{c.id}</a>
                  </td>
                  <td>
                    {c.firstName} {c.lastName}
                  </td>
                  <td>
                    <a
                      href={`mailto:${
                        users.find((u) => u.id == c.userId)?.email
                      }`}
                    >
                      {users.find((u) => u.id == c.userId)?.email}
                    </a>
                  </td>
                  <td>
                    <a
                      href={`mailto:${
                        users.find((u) => u.id == c.userId)?.phone
                      }`}
                    >
                      {users.find((u) => u.id == c.userId)?.phone}
                    </a>
                  </td>
                  <td>{c.location}</td>
                  <td class="centered">
                    <img src={c.pictureUrl} />
                  </td>
                  <td>{validation}/5</td>
                  <td>
                    {c.personalCoachingCategories.length > 0 &&
                    c.personalCoachingPrice &&
                    c.coords
                      ? 'Oui'
                      : 'Non'}
                  </td>
                  <td>{c.validated ? 'Oui' : 'Non'}</td>
                  <td>{moment(c.createdAt).format('LLL')}</td>
                  <td class="Coaches__actions">
                    <i onClick={editCoach(c)}>edit</i>
                    <i onClick={deleteCoach(c)}>close</i>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
