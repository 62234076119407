import { h } from 'preact'
import './Sessions.scss'
import httpClient from '../services/httpClient'
import { useState, useEffect } from 'preact/hooks'
import ROUTES from '../routes'
import { Order } from '../types/order'
import { CoachingClass } from '../types/coaching_class'
import { CoachingClassSession } from '../types/coaching_class_session'
import moment from 'moment'
import { RoutableProps } from 'preact-router'

export default (_: RoutableProps) => {
  const [filteredSessions, setFilteredSessions] = useState<
    CoachingClassSession[]
  >([])
  const [coachingClasses, setCoachingClasses] = useState<CoachingClass[]>([])
  const [sessions, setSessions] = useState<CoachingClassSession[]>([])
  const [query, setQuery] = useState('')
  const [orders, setOrders] = useState<Order[]>([])

  useEffect(() => {
    fetchCoachingClasses()
    fetchSessions()
    fetchOrders()
  }, [])

  useEffect(() => {
    let res = [...sessions]

    res = res.filter((s) =>
      coachingClasses
        .find((cc) => cc.id == s.coachingClassId)
        ?.name.toLowerCase()
        .includes(query.toLowerCase())
    )
    res.sort((a, b) => (moment(a.date).isBefore(moment(b.date)) ? 1 : -1))
    setFilteredSessions(res)
  }, [sessions, query, coachingClasses])

  const fetchCoachingClasses = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_COACHING_CLASSES({}))

      setCoachingClasses(res)
    } catch (e) {
      console.warn(e)
    }
  }

  const fetchSessions = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_ALL_SESSIONS({}))

      setSessions(res)
    } catch (e) {
      console.warn(e)
    }
  }

  const fetchOrders = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_ORDERS({}))

      setOrders(res)
    } catch (e) {
      console.warn(e)
    }
  }

  return (
    <div class="Sessions">
      <div class="Sessions__header">
        <div class="Sessions__title">
          Cours programmés <span>(total: {sessions.length})</span>
        </div>
        <div class="Sessions__search">
          <i>search</i>
          <input
            value={query}
            onInput={(e: any) => setQuery(e.target.value)}
            placeholder="Rechercher une session par nom de cours"
          ></input>
        </div>
      </div>
      <div class="Sessions__content">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Cours</th>
              <th>Date</th>
              <th>Nombre d'inscrits</th>
              <th>Annulée</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredSessions.map((s) => (
              <tr
                class={`Sessions__row${
                  s.canceled ? ' Sessions__row--warn' : ''
                }`}
              >
                <td>{s.id}</td>
                <td>
                  {
                    coachingClasses.find((cc) => cc.id == s.coachingClassId)
                      ?.name
                  }
                </td>
                <td>{moment(s.date).format('LLL')}</td>
                <td>
                  {orders.filter((o) => o.sessionIds.includes(s.id)).length}
                </td>
                <td>{s.canceled ? 'Oui' : 'Non'}</td>
                <td class="Sessions__actions">
                  <a href={`/orders/?session_id=${s.id}`}>
                    Voir les réservations
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
