import { h } from 'preact'
import './Reviews.scss'
import httpClient from '../services/httpClient'
import { useState, useEffect } from 'preact/hooks'
import ROUTES from '../routes'
import { User } from '../types/user'
import { RoutableProps } from 'preact-router'
import Coach from '../types/coach'
import { Review } from '../types/review'

interface Props extends RoutableProps {
  matches?: { session_id?: string }
}

export default (props: Props) => {
  const [filteredReviews, setFilteredReviews] = useState<Review[]>([])
  const [reviews, setReviews] = useState<Review[]>([])
  const [users, setUsers] = useState<User[]>([])
  const [coaches, setCoaches] = useState<Coach[]>([])
  const [query, setQuery] = useState('')

  useEffect(() => {
    fetchReviews()
    fetchUsers()
    fetchCoaches()
  }, [])

  useEffect(() => {
    let res = [...reviews]

    res = res.filter(
      (r) =>
        `${users.find((u) => u.id == r.userId)?.firstName} ${
          users.find((u) => u.id == r.userId)?.lastName
        }`
          .toLowerCase()
          .includes(query.toLowerCase()) ||
        `${coaches.find((c) => c.id == r.coachId)?.firstName} ${
          coaches.find((c) => c.id == r.coachId)?.lastName
        }`
          .toLowerCase()
          .includes(query.toLowerCase())
    )
    setFilteredReviews(res)
  }, [reviews, query, users, coaches])

  const fetchCoaches = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_COACHES({}))

      setCoaches(res)
    } catch (e) {
      console.warn(e)
    }
  }

  const fetchReviews = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_REVIEWS({}))

      setReviews(res)
    } catch (e) {
      console.warn(e)
    }
  }

  const fetchUsers = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_USERS({}))

      setUsers(res)
    } catch (e) {
      console.warn(e)
    }
  }

  const deleteReview = (r: Review) => async () => {
    if (
      confirm(
        'Êtes-vous sûr de vouloir supprimer cet avis ? Cette action est irréversible.'
      )
    ) {
      try {
        await httpClient.req(ROUTES.DELETE_REVIEW({ id: r.id }))

        setReviews(reviews.filter((r2) => r2.id != r.id))
      } catch (e) {
        console.warn(e)
      }
    }
  }

  return (
    <div class="Reviews">
      <div class="Reviews__header">
        <div class="Reviews__title">
          Avis <span>(total: {reviews.length})</span>
        </div>
        <div class="Reviews__search">
          <i>search</i>
          <input
            value={query}
            onInput={(e: any) => setQuery(e.target.value)}
            placeholder="Rechercher un avis par nom de coach ou utilisateur"
          ></input>
        </div>
      </div>
      <div class="Reviews__content">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Note</th>
              <th>Commentaire</th>
              <th>Utilisateur</th>
              <th>Coach</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredReviews.map((r) => (
              <tr>
                <td>{r.id}</td>
                <td>{r.rating}/5</td>
                <td>{r.comment}</td>
                <td>
                  {users.find((u) => u.id == r.userId)?.firstName}{' '}
                  {users.find((u) => u.id == r.userId)?.lastName}
                </td>
                <td>
                  {coaches.find((c) => c.id == r.coachId)?.firstName}{' '}
                  {coaches.find((c) => c.id == r.coachId)?.lastName}
                </td>
                <td class="Reviews__actions">
                  <i onClick={deleteReview(r)}>close</i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
