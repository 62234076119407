import { h } from 'preact'
import './Categories.scss'
import { useEffect, useState } from 'preact/hooks'
import httpClient from '../services/httpClient'
import ROUTES from '../routes'
import Toastify from 'toastify-js'
import Modal from '../components/Modal'
import { RoutableProps } from 'preact-router'
import { Category } from '../types/category'
import CategoryForm from './CategoryForm'

export default (_: RoutableProps) => {
  const [categories, setCategories] = useState<Category[]>([])
  const [selectedCategory, setSelectedCategory] = useState<Category>(undefined)
  const [showFormModal, setShowFormModal] = useState(false)

  useEffect(() => {
    fetchCategorys()
  }, [])

  const fetchCategorys = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_CATEGORIES({}))

      setCategories(res)
    } catch (e) {
      console.warn(e)
    }
  }

  const openFormModal = () => {
    setShowFormModal(true)
  }

  const closeFormModal = () => {
    setSelectedCategory(undefined)
    setShowFormModal(false)
  }

  const editCategory = (coach: Category) => () => {
    setSelectedCategory(coach)
    setShowFormModal(true)
  }

  const deleteCategory = (coach: Category) => async () => {
    try {
      await httpClient.req(ROUTES.DELETE_CATEGORY(coach))
      setCategories([...categories.filter((cc) => cc.id != coach.id)])
    } catch (err) {
      Toastify({
        text: 'Could not delete coach',
        duration: 5000,
        stopOnFocus: true,
        backgroundColor: '#f7436d',
      }).showToast()

      console.warn(err)
    } finally {
      setSelectedCategory(undefined)
    }
  }

  const onCategorySave = async (coach: Category) => {
    try {
      const route = coach.id ? ROUTES.UPDATE_CATEGORY : ROUTES.CREATE_CATEGORY
      const res = await httpClient.req(route(coach))

      if (coach.id) {
        let idx = categories.findIndex((cc) => cc.id === coach.id)

        categories[idx] = res
        setCategories([...categories])
      } else {
        setCategories([...categories, res])
      }

      setShowFormModal(false)
    } catch (err) {
      Toastify({
        text: 'Could not save category',
        duration: 5000,
        stopOnFocus: true,
        backgroundColor: '#f7436d',
      }).showToast()

      console.warn(err)
    } finally {
      setSelectedCategory(undefined)
    }
  }

  return (
    <div class="Categories">
      <div class="Categories__header">
        <div class="Categories__title">
          Catégories <span>(total: {categories.length})</span>
        </div>
        <div class="Categories__addButton" onClick={openFormModal}>
          <i>add</i>
        </div>
        {showFormModal && (
          <Modal onClose={closeFormModal}>
            <CategoryForm
              categories={categories}
              category={selectedCategory}
              onSave={onCategorySave}
            />
          </Modal>
        )}
      </div>
      <div class="Categories__content">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Icone</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {categories.map((cc) => (
              <tr>
                <td>{cc.id}</td>
                <td>{cc.name}</td>
                <td class="centered">
                  <img src={cc.iconUrl} />
                </td>
                <td class="Categories__actions">
                  <i onClick={editCategory(cc)}>edit</i>
                  <i onClick={deleteCategory(cc)}>close</i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
