import { h } from 'preact'
import './InvoiceGymspot.scss'
import { Order } from '../../types/order'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import moment from 'moment'
import { CoachingClass } from '../../types/coaching_class'
import { CoachingClassSession } from '../../types/coaching_class_session'
import Logo from '../../../assets/images/logo.svg'
import Coach from '../../types/coach'
import { useEffect } from 'react'
import httpClient from '../../services/httpClient'
import ROUTES from '../../routes'

interface Recipient {
  name: string
  address: string
  zipcode: string
  city: string
}

interface Props {
  order: Order
  recipient: Recipient
  sessions: CoachingClassSession[]
  coachingClasses: CoachingClass[]
  coaches: Coach[]
  coachInvoice?: boolean
}

export default (props: Props) => {
  const now = moment()
  const invoiceNumber = `FG${now.year()}${now.month()}${now.day()}${now.hour()}${now.minutes()}${now.seconds()}${now.milliseconds()}`

  const downloadPdf = () => {
    html2canvas(document.querySelector('.InvoiceGymspot__pdf')).then(
      async (canvas: any) => {
        const imgData = canvas.toDataURL('image/png')

        const pdf = new jsPDF('p', 'mm', [297, 210]) as any

        pdf.addImage(imgData, 'PNG', 0, 0, 210, 297)
        try {
          if (props.order.invoiceUrl == null) {
            const res = await httpClient.req(
              ROUTES.UPLOAD_FILE({ file: pdf.output('blob') })
            )

            await httpClient.req(
              ROUTES.UPDATE_ORDER({ ...props.order, invoiceUrl: res.fileUrl })
            )
            window.open(res.fileUrl, '_blank')
            window.location.reload()
          }
        } catch (e) {
          console.error('INVOICE UPLOAD ERROR: ', e)
        }
      }
    )
  }

  useEffect(() => {
    setTimeout(() => {
      downloadPdf()
    }, 500)
  }, [props.order])

  return (
    <div class="InvoiceGymspot" id={props.order.id}>
      <div class="InvoiceGymspot__content" onClick={(e) => e.stopPropagation()}>
        <div class="InvoiceGymspot__pdf">
          <div class="InvoiceGymspot__body">
            <div class="InvoiceGymspot__headerTitle">
              <div>FACTURE</div>
              <div class="InvoiceGymspot__headerTitleId">
                N° {invoiceNumber}
              </div>
            </div>
            <div class="InvoiceGymspot__header">
              <div class="InvoiceGymspot__headerLeft">
                <img src={Logo} class="InvoiceGymspot__headerLogo" />
                <div class="InvoiceGymspot__headerCompanyName">SAS GYMSPOT</div>
                <div class="InvoiceGymspot__headerCompanyAddress">
                  RCS Nanterre 882 848 310
                  <br />
                  N° TVA : FR03 882 848 310
                  <br />1 place Paul Verlaine
                  <br />
                  92100 Boulogne-Billancourt
                </div>

                <div class="InvoiceGymspot__headerDate">
                  Le {moment(new Date()).format('LL')}
                </div>
              </div>
              <div class="InvoiceGymspot__headerRight">
                <div class="InvoiceGymspot__headerRecipient">
                  {props.recipient.name}
                  <br />
                  {props.recipient.address}
                  <br />
                  {props.recipient.zipcode}
                  <br />
                  {props.recipient.city}
                </div>
                <div class="InvoiceGymspot__headerDate">
                  Commande du {moment(props.order.createdAt).format('LL')}
                  <br />
                  N°{props.order.id}
                </div>
              </div>
            </div>
            <div class="InvoiceGymspot__table">
              <table>
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Montant</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Frais Gympspot HT
                      <ul>
                        {props.order.sessionIds.map((s_id) => {
                          let session = props.sessions.find((s) => s.id == s_id)
                          let coachingClass = props.coachingClasses.find(
                            (cc) => cc.id == session.coachingClassId
                          )
                          let coach = props.coaches.find(
                            (c) => c.id == coachingClass.coachId
                          )

                          return (
                            <li>
                              Prestation ({coachingClass.name}) du{' '}
                              {moment(session.date).format('LLL')} avec{' '}
                              {coach.firstName} {coach.lastName}
                            </li>
                          )
                        })}
                      </ul>
                    </td>
                    <td class="centered">
                      {(((props.order.totalPrice * 15) / 100) * 0.8).toFixed(2)}
                      €
                    </td>
                  </tr>
                  <tr>
                    <td>TVA 20%</td>
                    <td>
                      {(((props.order.totalPrice * 15) / 100) * 0.2).toFixed(2)}
                      €
                    </td>
                  </tr>
                  <tr>
                    <td class="right">TOTAL TTC RECU</td>
                    <td class="centered">
                      {((props.order.totalPrice * 15) / 100).toFixed(2)}€
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="InvoiceGymspot__footer">
            Gymspot, RCS Nanterre 882 848 310, 1 place Paul Verlaine, 92100
            Boulogne-Billancourt
          </div>
        </div>
      </div>
    </div>
  )
}
