import { h } from 'preact'
import './CoachingClassForm.scss'
import { useState, useEffect } from 'preact/hooks'
import { CoachingClass } from '../../types/coaching_class'
import httpClient from '../../services/httpClient'
import ROUTES from '../../routes'
import Materialize from 'materialize-css'
import {
  GoogleMap,
  LoadScript,
  StandaloneSearchBox,
  Marker,
} from '@react-google-maps/api'
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import moment from 'moment'
import Category from '../../types/category'
import Coach from '../../types/coach'
import { Multiselect } from 'multiselect-react-dropdown'

interface Props {
  coachingClass?: CoachingClass
  categories: Category[]
  coaches: Coach[]
  onSave: (b: CoachingClass) => void
}

const libraries = ['places']

export default (props: Props) => {
  const [coachingClass, setCoachingClass] = useState<CoachingClass>(
    props.coachingClass || {
      name: '',
      description: '',
      coachId: '',
      categoryId: undefined,
      date: Date.now() / 1000,
      duration: 60,
      maxAttendees: 10,
      price: 60,
      pictureUrl: '',
      location: { lat: 48.866667, lng: 2.333333 },
    }
  )
  const [searchBox, setSearchBox] = useState(undefined)
  const [mapCenter, setMapCenter] = useState(
    coachingClass.location
      ? {
          lat: coachingClass.location.lat,
          lng: coachingClass.location.lng,
        }
      : undefined
  )
  const [marker, setMarker] = useState(
    coachingClass.location
      ? {
          lat: coachingClass.location.lat,
          lng: coachingClass.location.lng,
        }
      : undefined
  )
  const [mapZoom, setMapZoom] = useState(1)

  useEffect(() => {
    Materialize.updateTextFields()
    setTimeout(() => {
      setMapCenter({ ...mapCenter })
      setMapZoom(12)
    }, 500)
  }, [])

  const onSave = () => {
    props.onSave(coachingClass)
  }

  const uploadPhoto = async (e: any) => {
    const res = await httpClient.req(
      ROUTES.UPLOAD_FILE({ file: e.target.files[0] })
    )

    setCoachingClass({ ...coachingClass, pictureUrl: res.fileUrl })
  }

  const onLoad = (ref: any) => setSearchBox(ref)

  const onPlacesChanged = () => {
    const place = searchBox.getPlaces()[0]

    if (!place) {
      return
    }
    setMapCenter({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    })
  }

  const selectLocation = (e: any) => {
    setCoachingClass({
      ...coachingClass,
      location: { lat: e.latLng.lat(), lng: e.latLng.lng() },
    })
    setMarker({ lat: e.latLng.lat(), lng: e.latLng.lng() })
  }

  return (
    <div class="CoachingClassForm">
      <div class="CoachingClassForm__content">
        <div class="CoachingClassForm__left">
          <div class="input-field">
            <label for="coaching_class_name">Name</label>
            <input
              id="coaching_class_name"
              type="text"
              value={coachingClass.name}
              onChange={(e: any) =>
                setCoachingClass({ ...coachingClass, name: e.target.value })
              }
              class="CoachingClassForm__name"
            />
          </div>
          <div class="input-field">
            <label for="coaching_class_description">Description</label>
            <textarea
              id="coaching_class_description"
              value={coachingClass.description}
              onChange={(e: any) =>
                setCoachingClass({
                  ...coachingClass,
                  description: e.target.value,
                })
              }
              class="CoachingClassForm__description materialize-textarea"
            />
          </div>
          <div class="input-field">
            <label for="coaching_class_duration">Durée</label>
            <input
              type="number"
              id="coaching_class_duration"
              value={coachingClass.duration}
              onChange={(e: any) =>
                setCoachingClass({
                  ...coachingClass,
                  duration: +e.target.value,
                })
              }
              class="CoachingClassForm__duration materialize-textarea"
            />
          </div>
          <div class="input-field">
            <label for="coaching_class_price">Prix</label>
            <input
              type="number"
              id="coaching_class_price"
              value={coachingClass.price}
              onChange={(e: any) =>
                setCoachingClass({
                  ...coachingClass,
                  price: +e.target.value,
                })
              }
              class="CoachingClassForm__price materialize-textarea"
            />
          </div>
          <div class="input-field">
            <label for="coaching_class_max_attendees">
              Nombre max de personnes
            </label>
            <input
              type="number"
              id="coaching_class_max_attendees"
              value={coachingClass.maxAttendees}
              onChange={(e: any) =>
                setCoachingClass({
                  ...coachingClass,
                  maxAttendees: +e.target.value,
                })
              }
              class="CoachingClassForm__max_attendees materialize-textarea"
            />
          </div>
          {/* Select with all coaches */}
          <div class="input-field">
            <label for="coaching_class_coach_id">Coach ID</label>
            <input
              id="coaching_class_coach_id"
              type="text"
              value={coachingClass.coachId}
              onChange={(e: any) =>
                setCoachingClass({ ...coachingClass, coachId: e.target.value })
              }
              class="CoachingClassForm__userId"
            />
          </div>
          <div class="input-field">
            <Multiselect
              placeholder="Catégorie"
              options={props.categories}
              selectedValues={props.categories.filter(
                (c) => c.id == coachingClass.categoryId
              )}
              onSelect={(e: any) =>
                setCoachingClass({
                  ...coachingClass,
                  categoryId: e[0].id,
                })
              }
              onRemove={(e: any) =>
                setCoachingClass({
                  ...coachingClass,
                  categoryId: e[0].id,
                })
              }
              singleSelect
              displayValue="name"
            />
          </div>
          <div class="CoachingClassForm__date input-field">
            <label for="coaching_class_date">Date</label>
            <DateTimePicker
              placeholder="Date"
              defaultValue={moment(coachingClass.date * 1000).toDate()}
              onChange={(d) =>
                setCoachingClass({
                  ...coachingClass,
                  date: Math.floor(d.getTime() / 1000),
                })
              }
            />
          </div>
          <div class="input-field">
            <label for="coaching_class_meeting_location">
              Indication localisation
            </label>
            <input
              id="coaching_class_meeting_location"
              type="text"
              value={coachingClass.meetingLocation}
              onChange={(e: any) =>
                setCoachingClass({
                  ...coachingClass,
                  meetingLocation: e.target.value,
                })
              }
              class="CoachingClassForm__meetingLocation"
            />
          </div>
          <div style={{ width: '500px', height: '500px' }}>
            <LoadScript
              googleMapsApiKey="AIzaSyD6ZTSU7_HO4P8XXy72A1Dn9QAB7QF_XNo"
              libraries={libraries}
            >
              <GoogleMap
                onLoad={(map) => {
                  // @ts-ignore
                  const bounds = new window.google.maps.LatLngBounds()
                  map.fitBounds(bounds)
                }}
                center={mapCenter}
                id="CoachingClassForm__map"
                onClick={selectLocation}
                zoom={mapZoom}
              >
                <StandaloneSearchBox
                  onLoad={onLoad}
                  onPlacesChanged={onPlacesChanged}
                >
                  <input
                    type="text"
                    placeholder="Type an address"
                    style={{
                      backgroundColor: 'white',
                      boxSizing: `border-box`,
                      border: `1px solid transparent`,
                      width: `240px`,
                      height: `32px`,
                      padding: `0 12px`,
                      borderRadius: `3px`,
                      boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                      fontSize: `14px`,
                      outline: `none`,
                      textOverflow: `ellipses`,
                      position: 'absolute',
                      top: '10px',
                      right: '60px',
                    }}
                  />
                </StandaloneSearchBox>
                {marker && <Marker position={marker} />}
              </GoogleMap>
            </LoadScript>
          </div>
        </div>
        <div class="CoachingClassForm__right">
          {coachingClass.pictureUrl ? (
            <img src={coachingClass.pictureUrl} />
          ) : (
            <div class="CoachingClassForm__imagePlaceholder">No image yet</div>
          )}
          <label class="CoachingClassForm__fileInput">
            <input type="file" onChange={uploadPhoto} />
            Edit
          </label>
        </div>
      </div>
      <div className="CoachingClassForm__actions">
        <div class="CoachingClassForm__button" onClick={onSave}>
          VALIDATE
        </div>
      </div>
    </div>
  )
}
