import { h, JSX } from 'preact'
import './Modal.scss'

interface Props {
  onClose: () => void
  children: JSX.Element | JSX.Element[]
}

export default (props: Props) => {
  const close = () => {
    props.onClose()
  }

  const stopPropagation = (e: Event) => {
    e.stopPropagation()
  }

  return (
    <div class="Modal" onMouseDown={close}>
      <i class="Modal__close">close</i>
      <div class="Modal__content" onMouseDown={stopPropagation}>
        {props.children}
      </div>
    </div>
  )
}
