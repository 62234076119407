import { h } from 'preact'
import './CoachingClasses.scss'
import { useEffect, useState } from 'preact/hooks'
import httpClient from '../services/httpClient'
import ROUTES from '../routes'
import Toastify from 'toastify-js'
import Modal from '../components/Modal'
import { RoutableProps } from 'preact-router'
import { CoachingClass } from '../types/coaching_class'
import CoachingClassForm from './CoachingClassForm'
import Category from '../types/category'
import Coach from '../types/coach'
import { UUID } from '../types/common'

export default (_: RoutableProps) => {
  const [coachingClasses, setCoachingClasses] = useState<CoachingClass[]>([])
  const [coaches, setCoaches] = useState<Coach[]>([])
  const [categories, setCategories] = useState<Category[]>([])
  const [selectedCoachingClass, setSelectedCoachingClass] = useState<
    CoachingClass
  >(undefined)
  const [showFormModal, setShowFormModal] = useState(false)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    await fetchCategories()
    // await fetchCoaches()
    await fetchCoachingClasses()
  }

  const fetchCategories = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_CATEGORIES({}))

      setCategories(res)
    } catch (e) {
      console.warn(e)
    }
  }

  const fetchCoachingClasses = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_COACHING_CLASSES({}))

      setCoachingClasses(res)
    } catch (e) {
      console.warn(e)
    }
  }

  const openFormModal = () => {
    setShowFormModal(true)
  }

  const closeFormModal = () => {
    setSelectedCoachingClass(undefined)
    setShowFormModal(false)
  }

  const editCoachingClass = (coach: CoachingClass) => () => {
    setSelectedCoachingClass(coach)
    setShowFormModal(true)
  }

  const deleteCoachingClass = (coach: CoachingClass) => async () => {
    try {
      await httpClient.req(ROUTES.DELETE_COACHING_CLASS(coach))
      setCoachingClasses([...coachingClasses.filter((cc) => cc.id != coach.id)])
    } catch (err) {
      Toastify({
        text: 'Could not delete coaching class',
        duration: 5000,
        stopOnFocus: true,
        backgroundColor: '#f7436d',
      }).showToast()

      console.warn(err)
    } finally {
      setSelectedCoachingClass(undefined)
    }
  }

  const onCoachingClassSave = async (coach: CoachingClass) => {
    try {
      const route = coach.id
        ? ROUTES.UPDATE_COACHING_CLASS
        : ROUTES.CREATE_COACHING_CLASS
      const res = await httpClient.req(route(coach))

      if (coach.id) {
        let idx = coachingClasses.findIndex((cc) => cc.id === coach.id)

        coachingClasses[idx] = res
        setCoachingClasses([...coachingClasses])
      } else {
        setCoachingClasses([...coachingClasses, res])
      }

      setShowFormModal(false)
    } catch (err) {
      Toastify({
        text: 'Could not save coaching class',
        duration: 5000,
        stopOnFocus: true,
        backgroundColor: '#f7436d',
      }).showToast()

      console.warn(err)
    } finally {
      setSelectedCoachingClass(undefined)
    }
  }

  const category = (id: UUID) => categories.find((c) => c.id == id)

  return (
    <div class="CoachingClasses">
      <div class="CoachingClasses__header">
        <div class="CoachingClasses__title">
          Cours <span>(total: {coachingClasses.length})</span>
        </div>
        <div class="CoachingClasses__addButton" onClick={openFormModal}>
          <i>add</i>
        </div>
        {showFormModal && (
          <Modal onClose={closeFormModal}>
            <CoachingClassForm
              coachingClass={selectedCoachingClass}
              categories={categories}
              coaches={[]}
              onSave={onCoachingClassSave}
            />
          </Modal>
        )}
      </div>
      <div class="CoachingClasses__content">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Description</th>
              <th>Coach</th>
              <th>Catégorie</th>
              <th>Durée</th>
              <th>Prix</th>
              <th>Personnes max</th>
              <th>Image</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {coachingClasses.map((cc) => (
              <tr>
                <td>{cc.id}</td>
                <td>{cc.name}</td>
                <td>{cc.description}</td>
                <td>{cc.coachId}</td>
                <td>
                  {category(cc.categoryId)
                    ? category(cc.categoryId).name
                    : 'Catégorie inconnue'}
                </td>
                <td>{cc.duration}mins</td>
                <td>{cc.price}€</td>
                <td>{cc.maxAttendees}</td>
                <td class="centered">
                  <img src={cc.pictureUrl} />
                </td>
                <td class="CoachingClasss__actions">
                  <i onClick={editCoachingClass(cc)}>edit</i>
                  <i onClick={deleteCoachingClass(cc)}>close</i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
