import { h } from 'preact'
import './CoachDetails.scss'
import { RoutableProps } from 'preact-router'
import { useEffect, useState } from 'preact/hooks'
import httpClient from '../../services/httpClient'
import ROUTES from '../../routes'
import store, { Message } from '../../services/store'
import Coach from '../../types/coach'
import CoachDocument from '../../types/coach_document'

interface Props extends RoutableProps {
  id?: string
}

export default (props: Props) => {
  const [coach, setCoach] = useState<Coach>(undefined)
  const [coachDocuments, setCoachDocuments] = useState<CoachDocument[]>([])
  const [identityCard, setIdentityCard] = useState(undefined)
  const [professionalCard, setProfessionalCard] = useState(undefined)
  const [professionalCivilInsurance, setProfessionalCivilInsurance] = useState(
    undefined
  )
  const [billingMandate, setBillingMandate] = useState(undefined)

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    setIdentityCard(
      coachDocuments.find((cd) => cd.documentType == 'identity_card')
    )
    setProfessionalCard(
      coachDocuments.find((cd) => cd.documentType == 'professional_card')
    )
    setProfessionalCivilInsurance(
      coachDocuments.find(
        (cd) => cd.documentType == 'professional_civil_insurance'
      )
    )
    setBillingMandate(
      coachDocuments.find((cd) => cd.documentType == 'billing_mandate')
    )
  }, [coach, coachDocuments])

  useEffect(() => {
    let elems = document.querySelectorAll('select')

    M.FormSelect.init(elems, {})
  }, [
    identityCard,
    professionalCard,
    professionalCivilInsurance,
    billingMandate,
  ])

  const fetchData = async () => {
    await fetchCoach()
  }

  const fetchCoach = async () => {
    try {
      const c = await httpClient.req(ROUTES.FETCH_COACH({ id: props.id }))
      const cds = await httpClient.req(
        ROUTES.FETCH_COACH_DOCUMENTS({ coachId: c.id })
      )

      setCoach(c)
      setCoachDocuments(cds)
    } catch (e) {
      store.notify(
        Message.Error,
        'Impossible de récupérer les informations du coach'
      )
      console.warn(e)
    }
  }

  const changeIdentityCard = async (v: string) => {
    try {
      const cd = await httpClient.req(
        ROUTES.UPDATE_COACH_DOCUMENT({ id: identityCard.id, status: v })
      )

      setIdentityCard(cd)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de mettre à jour le document')
      console.warn(e)
    }
  }

  const changeProfessionalCard = async (v: string) => {
    try {
      const cd = await httpClient.req(
        ROUTES.UPDATE_COACH_DOCUMENT({ id: professionalCard.id, status: v })
      )

      setProfessionalCard(cd)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de mettre à jour le document')
      console.warn(e)
    }
  }

  const changeCivilInsurance = async (v: string) => {
    try {
      const cd = await httpClient.req(
        ROUTES.UPDATE_COACH_DOCUMENT({
          id: professionalCivilInsurance.id,
          status: v,
        })
      )

      setProfessionalCivilInsurance(cd)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de mettre à jour le document')
      console.warn(e)
    }
  }

  const changeBillingMandate = async (v: string) => {
    try {
      const cd = await httpClient.req(
        ROUTES.UPDATE_COACH_DOCUMENT({ id: billingMandate.id, status: v })
      )

      setBillingMandate(cd)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de mettre à jour le document')
      console.warn(e)
    }
  }

  const updateCoachValidated = async (v: boolean) => {
    try {
      const c = await httpClient.req(
        ROUTES.VALIDATE_COACH({ id: coach.id, validated: v })
      )

      setCoach(c)
    } catch (e) {
      store.notify(Message.Error, 'Impossible de mettre à jour le coach')
      console.warn(e)
    }
  }

  return (
    coach && (
      <div class="CoachDetails">
        <div class="CoachDetails__title">
          <h2>
            {coach.firstName} {coach.lastName}
          </h2>
          <label>
            <input
              type="checkbox"
              checked={coach.validated}
              onChange={(e: any) => updateCoachValidated(e.target.checked)}
            />
            <span>Validé</span>
          </label>
        </div>
        <table>
          <thead>
            <tr>
              <th>Document</th>
              <th>Lien</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class={`CoachDetails__row CoachDetails__row--${
                identityCard?.status || 'rejected'
              }`}
            >
              <td>Carte d'identité</td>
              <td>
                {identityCard && (
                  <a target="_blank" href={identityCard.fileUrl}>
                    Fichier
                  </a>
                )}
              </td>
              <td>
                {identityCard ? (
                  <select
                    value={identityCard.status}
                    onChange={(e: any) => changeIdentityCard(e.target.value)}
                  >
                    <option value="approved">Validé</option>
                    <option value="rejected">Refusé</option>
                    <option value="pending">En attente</option>
                  </select>
                ) : (
                  'Pas renseigné'
                )}
              </td>
            </tr>
            <tr
              class={`CoachDetails__row CoachDetails__row--${
                professionalCard?.status || 'rejected'
              }`}
            >
              <td>Carte professionnelle</td>
              <td>
                {professionalCard && (
                  <a target="_blank" href={professionalCard.fileUrl}>
                    Fichier
                  </a>
                )}
              </td>
              <td>
                {professionalCard ? (
                  <select
                    value={professionalCard.status}
                    onChange={(e: any) =>
                      changeProfessionalCard(e.target.value)
                    }
                  >
                    <option value="approved">Validé</option>
                    <option value="rejected">Refusé</option>
                    <option value="pending">En attente</option>
                  </select>
                ) : (
                  'Pas renseigné'
                )}
              </td>
            </tr>
            <tr
              class={`CoachDetails__row CoachDetails__row--${
                professionalCivilInsurance?.status || 'rejected'
              }`}
            >
              <td>Assurance civile professionnelle</td>
              <td>
                {professionalCivilInsurance && (
                  <a target="_blank" href={professionalCivilInsurance.fileUrl}>
                    Fichier
                  </a>
                )}
              </td>
              <td>
                {professionalCivilInsurance ? (
                  <select
                    value={professionalCivilInsurance.status}
                    onChange={(e: any) => changeCivilInsurance(e.target.value)}
                  >
                    <option value="approved">Validé</option>
                    <option value="rejected">Refusé</option>
                    <option value="pending">En attente</option>
                  </select>
                ) : (
                  'Pas renseigné'
                )}
              </td>
            </tr>
            <tr
              class={`CoachDetails__row CoachDetails__row--${
                billingMandate?.status || 'rejected'
              }`}
            >
              <td>Mandat de facturation</td>
              <td>
                <a
                  target="_blank"
                  href={`https://gymspot.eversign.com/dashboard`}
                >
                  Eversign
                </a>
              </td>
              <td>
                {billingMandate ? (
                  <select
                    value={billingMandate.status}
                    onChange={(e: any) => changeBillingMandate(e.target.value)}
                  >
                    <option value="approved">Validé</option>
                    <option value="rejected">Refusé</option>
                    <option value="pending">En attente</option>
                  </select>
                ) : (
                  'Pas renseigné'
                )}
              </td>
            </tr>
            <tr
              class={`CoachDetails__row CoachDetails__row--${
                coach.stripeUserId ? 'approved' : 'rejected'
              }`}
            >
              <td>Compte stripe lié ?</td>
              <td>
                <a
                  target="_blank"
                  href={`https://dashboard.stripe.com/connect/accounts/${coach.stripeUserId}`}
                >
                  Stripe
                </a>
              </td>
              <td>{coach.stripeUserId ? 'Oui' : 'Non'}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  )
}
