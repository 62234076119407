import { h, Fragment } from 'preact'
import './Button.scss'

interface Props {
  loading?: boolean
  value: string
  type?: string
  icon?: string
  onClick?: (e: MouseEvent) => void
  disabled?: boolean
}

const Button = (props: Props) => {
  const { value, loading, disabled, onClick, ...ownProps } = props

  return (
    <button
      onClick={disabled ? undefined : onClick}
      class={`btn waves-effect waves-light Button ${
        disabled ? 'Button--disabled' : ''
      }`}
      {...ownProps}
    >
      {props.value}
      {props.icon && <i class="material-icons right">{props.icon}</i>}
    </button>
  )
}

export default Button
