import { h, render } from 'preact'
import './default.scss'
import 'toastify-js/src/toastify.css'
import App from './app'
import Moment from 'moment'
import 'moment/locale/fr'

import momentLocalizer from 'react-widgets-moment'

Moment.locale('fr')
momentLocalizer()

render(<App />, document.body)
