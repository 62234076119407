import { h } from 'preact'
import { useState } from 'preact/hooks'
import store, { Stored, Message } from './services/store'
import Layout from './Layout'
import Login from './Login'

export default () => {
  const [loggedIn, setLoggedIn] = useState(store.store.RawJWT)

  store.listen(Stored.RawJWT, setLoggedIn)
  store.listen(Message.NeedAuth, () => {
    store.update(Stored.JWT, undefined)
    store.update(Stored.RawJWT, undefined)
    store.update(Stored.RefreshToken, undefined)
  })

  return loggedIn ? <Layout /> : <Login />
}
