import { h } from 'preact'
import { useState, useEffect } from 'preact/hooks'
import Button from '../components/Button'
import httpClient from '../services/httpClient'
import store, { Message, Stored } from '../services/store'
import ROUTES from '../routes'
import Toastify from 'toastify-js'
import Materialize from 'materialize-css'
import './Login.scss'

const Login = () => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    Materialize.updateTextFields()
  }, [])

  const submit = async (e: any) => {
    try {
      e.preventDefault()

      setLoading(true)
      const els = e.target.elements
      const payload = {
        email: els.email.value,
        password: els.password.value,
      }
      const res = await httpClient.req(ROUTES.LOGIN(payload))

      if (res.role == 'admin') {
        const [rawJwt, jwt, token] = httpClient.storeCreds(res)

        store.update(Stored.JWT, jwt)
        store.update(Stored.RawJWT, rawJwt)
        store.update(Stored.RefreshToken, token)
        store.notify(Message.Notification, 'Vous êtes connectés avec succès')
      } else {
        Toastify({
          text: 'Email ou mot de passe incorrect',
          duration: 5000,
          stopOnFocus: true,
          backgroundColor: '#f7436d',
        }).showToast()
      }
    } catch (err) {
      console.warn(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div class="container">
      <form onSubmit={submit} class="Login">
        <div class="row">
          <div class="Login__Title col offset-s3">
            Merci de vous connecter pour continuer
          </div>
        </div>
        <div class="Login__Inputs">
          <div class="row">
            <div class="input-field col s6 offset-s3">
              <label for="email">Email</label>
              <input id="email" name="email" type="email" />
            </div>
          </div>
          <div class="row">
            <div class="input-field col s6 offset-s3">
              <label for="password">Mot de passe</label>
              <input id="password" name="password" type="password" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <Button loading={loading} value={'Se connecter'} type="submit" />
          </div>
        </div>
      </form>
    </div>
  )
}

export default Login
