import { h } from 'preact'
import './Users.scss'
import { User } from '../types/user'
import { useState, useEffect } from 'preact/hooks'
import httpClient from '../services/httpClient'
import ROUTES from '../routes'
import Toastify from 'toastify-js'
import { RoutableProps } from 'preact-router'

export default (props: RoutableProps) => {
  const [users, setUsers] = useState<User[]>([])
  const [filteredUsers, setFilteredUsers] = useState<User[]>([])
  const [query, setQuery] = useState('')

  useEffect(() => {
    fetchUsers()
  }, [])

  useEffect(() => {
    refreshFilter()
  }, [users, query])

  const fetchUsers = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_USERS({}))

      setUsers(res)
    } catch (e) {
      console.warn(e)
    }
  }

  const refreshFilter = () => {
    setFilteredUsers(
      users
        .filter(
          (u: User) =>
            `${u.firstName.toLocaleLowerCase()} ${u.lastName.toLocaleLowerCase()}`.includes(
              query.toLocaleLowerCase()
            ) || u.email.toLocaleLowerCase().includes(query.toLocaleLowerCase())
        )
        .sort((a, b) => (a.email > b.email ? 1 : -1))
    )
  }

  const updateAdmin = async (user: User, admin: boolean) => {
    try {
      let res = await httpClient.req(
        ROUTES.UPDATE_USER({ ...user, role: admin ? 'admin' : 'user' })
      )
      setUsers([...users.filter((u) => u.id != user.id), res])
    } catch (err) {
      Toastify({
        text: 'Could not update user',
        duration: 5000,
        stopOnFocus: true,
        backgroundColor: '#f7436d',
      }).showToast()
      setUsers({ ...users })
      console.warn(err)
    }
  }

  return (
    <div class="Users">
      <div class="Users__header">
        <div class="Users__title">
          Utilisateurs{' '}
          <span>
            (total: {users.length}, admins:{' '}
            {users.filter((u) => u.role == 'admin').length})
          </span>
        </div>
        <div class="Users__search">
          <i>search</i>
          <input
            value={query}
            onInput={(e: any) => setQuery(e.target.value)}
            placeholder="Search a user by name or email..."
          ></input>
        </div>
      </div>
      <div class="Users__content">
        <table>
          <thead>
            <tr>
              <th>Id</th>
              <th>Nom</th>
              <th>Email</th>
              <th>Admin</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((u) => (
              <tr>
                <td>{u.id}</td>
                <td>
                  {u.firstName} {u.lastName}
                </td>
                <td>{u.email}</td>
                <td>
                  <label>
                    <input
                      type="checkbox"
                      checked={u.role == 'admin'}
                      onChange={(e: any) => updateAdmin(u, e.target.checked)}
                    />
                    <span></span>
                  </label>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
