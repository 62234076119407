import axios, { AxiosResponse } from 'axios'
import humps from 'humps'
import jwtDecode from 'jwt-decode'
import ROUTES, { Method, Route, BASE_URL } from '../routes'
import { AuthResponse, JWT } from '../types/auth'
import store, { Message, Stored } from './store'

interface Request {
  resolve: any
  reject: any
  route: Route
}

type ErrorHandler = (err: string | undefined) => void

class HttpClient {
  public pendingRequests: Request[]
  public isRefreshing?: boolean
  public jwt?: JWT
  public rawJwt?: string
  public token?: string

  constructor() {
    const savedState = JSON.parse(localStorage.getItem('state')) || {}

    this.pendingRequests = []
    this.jwt = savedState[Stored.JWT]
    this.rawJwt = savedState[Stored.RawJWT]
    this.token = savedState[Stored.RefreshToken]
  }

  public req(route: Route, errorHandler?: ErrorHandler): Promise<any> {
    let { params, method, data, auth, file, skipHumps } = route
    const headers: any = {}

    if (auth) {
      if (this.isRefreshing) {
        return this.setPendingRequest(route)
      }
      if (!this.jwt) {
        store.notify(Message.NeedAuth)
        return Promise.reject('Please log in to continue')
      }
      if (this.jwt.exp <= new Date().getTime() / 1000 + 60) {
        const pendingRequest = this.setPendingRequest(route)

        this.isRefreshing = true
        this.refreshJwt()
        return pendingRequest
      }
      headers.Authorization = `Bearer ${this.rawJwt}`
    }
    if (file) {
      data = new FormData()
      data.append('file', file)
    }
    return [Method.Get, Method.Delete].includes(method)
      ? (axios[method] as any)(this.genUrl(route), {
          params: humps.decamelizeKeys(params),
          headers,
        })
          .then((e: any) => (skipHumps ? e.data : humps.camelizeKeys(e.data)))
          .catch((err: { response?: AxiosResponse }) =>
            this.handleError(err, errorHandler)
          )
      : (axios[method] as any)(
          this.genUrl(route),
          file ? data : humps.decamelizeKeys(data),
          {
            params: humps.decamelizeKeys(params),
            headers,
          }
        )
          .then((e: any) => (skipHumps ? e.data : humps.camelizeKeys(e.data)))
          .catch((err: { response?: AxiosResponse }) =>
            this.handleError(err, errorHandler)
          )
  }

  public storeCreds(res: AuthResponse): [string, JWT, string] {
    const state = JSON.parse(localStorage.getItem('state')) || {}

    this.jwt = jwtDecode<JWT>(res.jwt)
    this.rawJwt = res.jwt
    this.token = res.refreshToken
    state[Stored.JWT] = this.jwt
    state[Stored.RawJWT] = this.rawJwt
    state[Stored.RefreshToken] = this.token
    localStorage.setItem('state', JSON.stringify(state))
    return [this.rawJwt, this.jwt, this.token]
  }

  private handleError(
    error: { response?: AxiosResponse },
    handler?: ErrorHandler
  ) {
    const err =
      (error.response && error.response.data && error.response.data.error) ||
      undefined

    if (handler) {
      handler(err)
    } else if (error.response) {
      store.notify(
        Message.Error,
        err || 'Please check your internet connection'
      )
    } else {
      store.notify(Message.Error, 'Please check your internet connection')
    }
    throw error
  }

  private refreshJwt() {
    if (!this.token) {
      return store.notify(Message.NeedAuth)
    }
    this.req(ROUTES.REFRESH_JWT({ token: this.token }))
      .then((res: AuthResponse) => {
        this.isRefreshing = false
        this.storeCreds(res)
        this.pendingRequests.forEach((r) => {
          this.req(r.route).then(r.resolve).catch(r.reject)
        })
      })
      .catch(() => {
        this.pendingRequests = []
        store.notify(Message.NeedAuth)
      })
  }

  private genUrl(route: Route): string {
    const urlReg = /^(?:https?:\/\/)?([0-9a-z-.:]+)\/?/i
    const baseUrl = BASE_URL.replace(urlReg, 'https://$1/')
    return baseUrl + route.path
  }

  private setPendingRequest(route: Route): Promise<any> {
    const promise = new Promise((resolve: any, reject: any) => {
      this.pendingRequests.push({ resolve, reject, route })
    })

    return promise
  }
}

const httpClient = new HttpClient()

export default httpClient
