import { UUID } from './types/common'
import { User } from './types/user'
import { CoachingClass } from './types/coaching_class'
import Category from './types/category'
import CoachDocument from './types/coach_document'
import Coach from './types/coach'
import { Order } from './types/order'

export enum Method {
  Get = 'get',
  Post = 'post',
  Patch = 'patch',
  Delete = 'delete',
}

export interface Route {
  path: string
  method: Method
  data?: any
  params?: any
  auth?: boolean
  file?: File
  skipHumps?: boolean
}

export const BASE_URL = 'https://api.gymspot.fr/'

const ROUTES: { [name: string]: (payload?: any) => Route } = {
  LOGIN: (data: { emaile: String; password: String }) => ({
    path: '/users/login',
    method: Method.Post,
    auth: false,
    data,
  }),
  REFRESH_JWT: (data: { token: String }) => ({
    path: `/users/refresh/${data.token}`,
    method: Method.Get,
    auth: false,
    data,
  }),
  FETCH_USERS: (data: {}) => ({
    path: '/users',
    method: Method.Get,
    auth: true,
    data,
  }),
  UPDATE_USER: (data: User) => ({
    path: `/users/${data.id}`,
    method: Method.Patch,
    auth: true,
    data,
  }),
  FETCH_COACHING_CLASSES: (data: {}) => ({
    path: '/coaching_classes',
    method: Method.Get,
    auth: false,
    data,
  }),
  UPDATE_COACHING_CLASS: (data: CoachingClass) => ({
    path: `/coaching_classes/${data.id}`,
    method: Method.Patch,
    auth: true,
    data,
  }),
  CREATE_COACHING_CLASS: (data: CoachingClass) => ({
    path: `/coaching_classes`,
    method: Method.Post,
    auth: true,
    data,
  }),
  DELETE_COACHING_CLASS: (data: CoachingClass) => ({
    path: `/coaching_classes/${data.id}`,
    method: Method.Delete,
    auth: true,
    data,
  }),
  FETCH_CATEGORIES: (data: {}) => ({
    path: '/categories',
    method: Method.Get,
    auth: false,
    data,
  }),
  UPDATE_CATEGORY: (data: Category) => ({
    path: `/categories/${data.id}`,
    method: Method.Patch,
    auth: true,
    data,
  }),
  CREATE_CATEGORY: (data: Category) => ({
    path: `/categories`,
    method: Method.Post,
    auth: true,
    data,
  }),
  DELETE_CATEGORY: (data: Category) => ({
    path: `/categories/${data.id}`,
    method: Method.Delete,
    auth: true,
    data,
  }),
  UPLOAD_FILE: (params: { file: File }) => ({
    path: `/files/`,
    method: Method.Post,
    auth: false,
    file: params.file,
  }),
  FETCH_COACHES: (data: {}) => ({
    path: '/coaches',
    method: Method.Get,
    auth: false,
    data,
  }),
  FETCH_COACH: (data: { id: UUID }) => ({
    path: `/coaches/${data.id}`,
    method: Method.Get,
    auth: false,
    data,
  }),
  FETCH_COACH_DOCUMENTS: (data: { coachId: UUID }) => ({
    path: `/coaches/${data.coachId}/documents`,
    method: Method.Get,
    auth: true,
    data,
  }),
  FETCH_ALL_COACH_DOCUMENTS: (data: {}) => ({
    path: `/coach_documents/`,
    method: Method.Get,
    auth: true,
    data,
  }),
  UPDATE_COACH_DOCUMENT: (data: CoachDocument) => ({
    path: `/coach_documents/${data.id}`,
    method: Method.Patch,
    auth: true,
    data,
  }),
  UPDATE_COACH: (data: Coach) => ({
    path: `/coaches/${data.id}`,
    method: Method.Patch,
    auth: true,
    data,
  }),
  VALIDATE_COACH: (data: { id: UUID; validated: boolean }) => ({
    path: `/coaches/${data.id}/validate`,
    method: Method.Patch,
    auth: true,
    data,
  }),
  CREATE_COACH: (data: Category) => ({
    path: `/coaches`,
    method: Method.Post,
    auth: true,
    data,
  }),
  DELETE_COACH: (data: Category) => ({
    path: `/coaches/${data.id}`,
    method: Method.Delete,
    auth: true,
    data,
  }),
  FETCH_ALL_SESSIONS: (data: {}) => ({
    path: `/coaching_class_sessions`,
    method: Method.Get,
    auth: false,
    data,
  }),
  FETCH_ORDERS: (data: {}) => ({
    path: `/orders`,
    method: Method.Get,
    auth: true,
    data,
  }),
    UPDATE_ORDER: (data: Order & { invoiceUrl: string }) => ({
    path: `/orders/${data.id}`,
    method: Method.Patch,
    auth: true,
    data,
  }),
  FETCH_REVIEWS: (data: {}) => ({
    path: `/reviews`,
    method: Method.Get,
    auth: true,
    data,
  }),
  DELETE_REVIEW: (data: { id: UUID }) => ({
    path: `/reviews/${data.id}`,
    method: Method.Delete,
    auth: true,
    data,
  }),
}

export default ROUTES
