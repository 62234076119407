import { h } from 'preact'
import { Router } from 'preact-router'
import './Layout.scss'
import Users from '../Users'
import CoachingClasses from '../CoachingClasses'
import Categories from '../Categories'
import Coaches from '../Coaches'
import CoachDetails from '../Coaches/CoachDetails'
import Orders from '../Orders'
import Sessions from '../Sessions'
import Reviews from '../Reviews'

export default () => {
  return (
    <div class="Layout">
      <nav class="nav-extended">
        <div class="nav-wrapper">
          <a href="/" class="brand-logo">
            Gymspot
          </a>
          <ul id="nav-mobile" class="right">
            <li>
              <a href="/users">Users</a>
            </li>
            <li>
              <a href="/categories">Catégories</a>
            </li>
            <li>
              <a href="/coaching_classes">Cours</a>
            </li>
            <li>
              <a href="/coaches">Coaches</a>
            </li>
            <li>
              <a href="/orders">Commandes</a>
            </li>
            <li>
              <a href="/sessions">Sessions programmées</a>
            </li>
            <li>
              <a href="/reviews">Avis</a>
            </li>
          </ul>
        </div>
      </nav>
      <div class="Layout__content">
        <Router>
          <Users path="/users" default />
          <CoachingClasses path="/coaching_classes" />
          <Categories path="/categories" />
          <Coaches path="/coaches" />
          <CoachDetails path="/coaches/:id" />
          <Orders path="/orders" />
          <Sessions path="/sessions" />
          <Reviews path="/reviews" />
        </Router>
      </div>
    </div>
  )
}
